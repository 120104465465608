<template>
  <div class="comment-list">
    <comment-summary :comment-summary="commentSummary"></comment-summary>
    <comment-contents
      v-for="item in commentList"
      :key="item.id"
      :comment-info="item"
    ></comment-contents>
    <a-empty v-if="!loading && total === 0" />
    <op-spin v-if="loading"></op-spin>
    <div class="comment-list__pagination">
      <a-pagination
        v-model="currentPage"
        size="small"
        :total="total"
        :default-page-size="pageSize"
        :show-total="
          total => `${$t('common.pagination.total')} ${total} ${$t('common.pagination.items')}`
        "
        @change="getCommentList"
      />
    </div>
  </div>
</template>

<script>
import { getCommentList, getCommentSummary } from '@/api'
export default {
  props: {},
  data() {
    return {
      commentList: [],
      commentSummary: {},
      currentPage: 1,
      pageSize: 20,
      total: 0,
      loading: true,
    }
  },

  computed: {},

  created() {
    this.getCommentList()
    this.getCommentSummary()
  },

  methods: {
    async getCommentList() {
      try {
        this.loading = true
        const params = {
          size: this.pageSize,
          start: this.pageSize * (this.currentPage - 1),
          goodsId: this.$route.query.goodsId,
        }
        const { data, totalRecords } = await getCommentList(params)
        this.commentList = data || []
        this.total = totalRecords || 0
      } finally {
        this.loading = false
      }
    },
    async getCommentSummary() {
      const { data } = await getCommentSummary()
      this.commentSummary = data || {}
    },
  },
}
</script>
<style lang="less" scoped>
.comment-list {
  width: 1440px;
  max-width: calc(100% - 80px);
  margin: 0 auto;
  &__pagination {
    display: flex;
    justify-content: flex-end;
    margin: 24px 0;
  }
}
@media (max-width: 1024px) {
  .comment-list {
    max-width: calc(100% - 80px);
  }
}
@media (max-width: 576px) {
  .comment-list {
    max-width: calc(100% - 32px);
  }
}
</style>
